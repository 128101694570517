import {
  MenuItem, Select, FormHelperText, FormControl,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { INFO_COLOR_LIGHT } from '../app/constants'
import PlaceholderComponent from './FormPlaceholderComponent'
import ErrorComponent from './FormErrorComponent'

const menuPropsStyles = {
  '&& .Mui-selected': {
    backgroundColor: INFO_COLOR_LIGHT,
  },
  '&& .Mui-selected:hover': {
    backgroundColor: INFO_COLOR_LIGHT,
  },
  '&& .Mui-selected.Mui-focusVisible': {
    backgroundColor: INFO_COLOR_LIGHT,
  },
}

const FormInputDropdown = ({
  control,
  errors,
  isDisabled,
  name,
  options,
  placeholder,
  required,
  clearStateErrors,
  helperText, // added helperText prop
}: {
  control: any;
  errors: any;
  isDisabled?: boolean;
  name: string;
  options: any[];
  placeholder: string;
  required?: boolean;
  clearStateErrors?: any;
  helperText?: string;
}) => {
  const generateSingleOptions = () => options.map((option: any) => (
    <MenuItem
      key={option.value}
      value={option.value}
    >
      {option.label}
    </MenuItem>
  ))

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          onChange, onBlur, value, ref,
        },
      }) => (
        <FormControl
          error={!!errors[name]}
          fullWidth
        >
          <Select
            color="info"
            disabled={isDisabled}
            displayEmpty
            inputRef={ref}
            MenuProps={{
              sx: { ...menuPropsStyles },
              PaperProps: { sx: { maxHeight: 400 } },
            }}
            name={`select-${name}`}
            onBlur={onBlur}
            onChange={(e) => {
              if (clearStateErrors) {
                clearStateErrors('zipCode')
              }
              onChange(e)
            }}
            renderValue={(rValue) => (rValue !== '' ? (
              options.find((item) => item.value === value)?.label
            ) : (
              <PlaceholderComponent
                placeholder={placeholder}
                required={required}
              />
            ))}
            size="small"
            sx={{ textAlign: 'start' }}
            value={value || ''}
          >
            {generateSingleOptions()}
          </Select>
          {helperText && (
          <FormHelperText sx={{ color: 'text.secondary' }}>
            {helperText}
          </FormHelperText>
          )}
          {errors[name] && <ErrorComponent message={errors[name].message} />}
        </FormControl>
      )}
      rules={{ required: required && 'This field is required.' }}
    />
  )
}

FormInputDropdown.defaultProps = {
  isDisabled: false,
  required: false,
}

export default FormInputDropdown
