import React from 'react'
import { IFormItem, FormInputProps } from '../../../app/types'
import FormInputText from '../../../components/FormInputText'
import FormSection from '../../../components/FormSection'
import {
  RELATION_OPTIONS,
  LANGUAGE_OPTIONS,
  STATE_OPTIONS,
  SUBS,
  MAX_LENGTHS,
  NAME_PATTERN,
  NAME_INVALID_MESSAGE,
} from '../../../app/constants'
import FormInputDate from '../../../components/FormInputDate'
import FormInputDropdown from '../../../components/FormInputDropdown'
import DateOfBirthHelp from '../../../components/DateOfBirthHelp'
import usePathTranslation from '../../../hooks/pathTranslation'

export const PatientInfoForm = ({
  control,
  errors,
  required,
}: FormInputProps) => {
  const { t } = usePathTranslation()
  const patientDetails: IFormItem[] = [
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.FIRST_NAME}
          name="patientFirstName"
          pattern={{ value: NAME_PATTERN, message: NAME_INVALID_MESSAGE }}
          placeholder={t('self_scheduling.initial_form.patient_first_name')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.LAST_NAME}
          name="patientLastName"
          pattern={{ value: NAME_PATTERN, message: NAME_INVALID_MESSAGE }}
          placeholder={t('self_scheduling.initial_form.patient_last_name')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDate
          control={control}
          errors={errors}
          helperText={<DateOfBirthHelp />}
          isLessThan20MonthsOld
          name="dateOfBirth"
          placeholder={t('self_scheduling.initial_form.patient_dob')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDropdown
          control={control}
          errors={errors}
          name="gender"
          options={[
            { value: 'M', label: t('self_scheduling.initial_form.gender_male') },
            { value: 'F', label: t('self_scheduling.initial_form.gender_female') },
            { value: 'U', label: t('self_scheduling.initial_form.gender_undefined') },
          ]}
          placeholder={t('self_scheduling.initial_form.patient_gender')}
          required={required}
        />
      ),
    },
  ]

  return (
    <FormSection
      data={patientDetails}
      sub={SUBS.required}
      title={t('self_scheduling.initial_form.patient_information')}
    />
  )
}

export const AddressForm = ({
  control,
  errors,
  required,
  getAddressValues,
  clearStateErrors,
}: FormInputProps) => {
  const { t } = usePathTranslation()
  const patientDetails: IFormItem[] = [
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.FIRST_LINE_ADDRESS}
          name="firstLine"
          placeholder={t('self_scheduling.initial_form.first_line')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.SECOND_LINE_ADDRESS}
          name="secondLine"
          placeholder={t('self_scheduling.initial_form.second_line')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDropdown
          control={control}
          errors={errors}
          name="country"
          options={[{ value: 'US', label: 'United States' }]}
          placeholder={t('self_scheduling.initial_form.country')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.CITY}
          name="city"
          placeholder={t('self_scheduling.initial_form.city')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDropdown
          clearStateErrors={clearStateErrors}
          control={control}
          errors={errors}
          name="stateCode"
          options={STATE_OPTIONS}
          placeholder={t('self_scheduling.initial_form.state')}
          required
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          getAddressValues={getAddressValues}
          maxLength={MAX_LENGTHS.ZIP_CODE}
          name="zipCode"
          placeholder={t('self_scheduling.initial_form.zip_code')}
          required={required}
          type="zipcode"
        />
      ),
    },
  ]

  return (
    <FormSection
      data={patientDetails}
      sub={SUBS.required}
      title={t('self_scheduling.initial_form.address')}
    />
  )
}

export const Guardian1Form = ({
  control,
  errors,
  required,
}: FormInputProps) => {
  const { t } = usePathTranslation()
  const guardianDetails1: IFormItem[] = [
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.FIRST_NAME}
          name="guardianFirstName"
          pattern={{ value: NAME_PATTERN, message: NAME_INVALID_MESSAGE }}
          placeholder={t('self_scheduling.initial_form.guardian_first_name')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.LAST_NAME}
          name="guardianLastName"
          pattern={{ value: NAME_PATTERN, message: NAME_INVALID_MESSAGE }}
          placeholder={t('self_scheduling.initial_form.guardian_last_name')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDropdown
          control={control}
          errors={errors}
          name="guardianRelation"
          options={RELATION_OPTIONS}
          placeholder={t('self_scheduling.initial_form.guardian_relation')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          helperText={t('self_scheduling.initial_form.guardian_email_helper')}
          maxLength={MAX_LENGTHS.EMAIL}
          name="guardianEmail"
          placeholder={t('self_scheduling.initial_form.guardian_email')}
          required={required}
          type="email"
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          helperText={t('self_scheduling.initial_form.guardian_phone_helper')}
          maxLength={MAX_LENGTHS.PHONE_NUMBER}
          name="guardianPhoneNumber"
          placeholder={t('self_scheduling.initial_form.guardian_phone')}
          required={required}
          type="tel"
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDropdown
          control={control}
          errors={errors}
          helperText={t('self_scheduling.initial_form.language_helper')}
          name="language"
          options={LANGUAGE_OPTIONS}
          placeholder={t('self_scheduling.initial_form.language')}
          required={required}
        />
      ),
    },
  ]

  return (
    <FormSection
      data={guardianDetails1}
      sub={SUBS.required}
      title={t('self_scheduling.initial_form.guardian_information')}
    />
  )
}

export const Guardian2Form = ({
  control,
  errors,
  required,
}: FormInputProps) => {
  const { t } = usePathTranslation()
  const guardianDetails2: IFormItem[] = [
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.FIRST_NAME}
          name="guardian2FirstName"
          pattern={{ value: NAME_PATTERN, message: NAME_INVALID_MESSAGE }}
          placeholder={t('self_scheduling.initial_form.guardian_first_name')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.LAST_NAME}
          name="guardian2LastName"
          pattern={{ value: NAME_PATTERN, message: NAME_INVALID_MESSAGE }}
          placeholder={t('self_scheduling.initial_form.guardian_last_name')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDropdown
          control={control}
          errors={errors}
          name="guardian2Relation"
          options={RELATION_OPTIONS}
          placeholder={t('self_scheduling.initial_form.guardian_relation')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          helperText={t('self_scheduling.initial_form.guardian_email_helper')}
          maxLength={MAX_LENGTHS.EMAIL}
          name="guardian2Email"
          placeholder={t('self_scheduling.initial_form.guardian_email')}
          required={required}
          type="email"
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          helperText={t('self_scheduling.initial_form.guardian_phone_helper')}
          maxLength={MAX_LENGTHS.PHONE_NUMBER}
          name="guardian2PhoneNumber"
          placeholder={t('self_scheduling.initial_form.guardian_phone')}
          required={required}
          type="tel"
        />
      ),
    },
  ]

  return (
    <FormSection
      data={guardianDetails2}
      sub={{ ...SUBS.optional, text: t('self_scheduling.initial_form.optional') }}
      title={t('self_scheduling.initial_form.guardian2_information')}
    />
  )
}
