import {
  addHours,
  isAfter,
} from 'date-fns'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import {
  APPOINTMENT_HOUR_DIFF,
  DATE_PATTERNS,
  SS_ENDPOINT,
} from '../../../app/constants'
import { IAppointment } from '../../../app/types'
import sendSentryError from '../../../utils/sentryError'

import('dayjs/locale/es')

type DateTimesMap = { [key: string]: string[] }

async function getAvailableAppointmentsSlots(
  date: dayjs.Dayjs,
  serviceCenterID: string,
  language: string,
  Guardian1Email: string,
) {
  // Define the global cutoff: 42 days for the special service center; otherwise 14 days.
  const specialCenters = ['3904795']
  const globalMaxDate = specialCenters.includes(String(serviceCenterID))
    ? dayjs().add(42, 'day')
    : dayjs().add(14, 'day')

  // Use the selected date's week for the API query.
  const firstDayOfWeek = date.startOf('week')
  // Calculate the candidate end of the week.
  const candidateLastDay = firstDayOfWeek.add(6, 'day')
  // Use the global cutoff if it comes sooner.
  const lastDayOfWeek = candidateLastDay.isAfter(globalMaxDate)
    ? globalMaxDate
    : candidateLastDay

  const params = language
    ? `service_center_id=${serviceCenterID}&start_date=${firstDayOfWeek.format(DATE_PATTERNS.iso_8601_date)}&end_date=${lastDayOfWeek.format(DATE_PATTERNS.iso_8601_date)}&language=${language}&email=${Guardian1Email}`
    : `service_center_id=${serviceCenterID}&start_date=${firstDayOfWeek.format(DATE_PATTERNS.iso_8601_date)}&end_date=${lastDayOfWeek.format(DATE_PATTERNS.iso_8601_date)}&email=${Guardian1Email}`

  if (serviceCenterID) {
    const resp = axios.get(
      `${SS_ENDPOINT}/api/get-available-appointments-slots?${params}`,
    )
    return resp
  }
  return null
}

export function getWeekDates(date: dayjs.Dayjs): dayjs.Dayjs[] {
  // first day of the week
  const firstDay = date.startOf('week')

  // generate array of 7 days
  const weekDates = [...Array(7)].map((_, i) => firstDay.add(i, 'day'))

  return weekDates
}

// Gets the month and year with the most dates.
export function getMonthYear(dates: string[], lang: string = 'en'): string {
  dayjs.locale(lang)
  const dateCounts: { [key: string]: number } = {}
  let maxMonthYear = { month: 0, year: 0, count: 0 }

  dates.forEach((dateString) => {
    const date = dayjs(dateString)
    const month = date.month() + 1
    const year = date.year()
    const key = `${month}/${year}`

    dateCounts[key] = (dateCounts[key] || 0) + 1

    if (dateCounts[key] > maxMonthYear.count) {
      maxMonthYear = { month, year, count: dateCounts[key] }
    }
  })

  const monthName = dayjs()
    .month(maxMonthYear.month - 1)
    .format(DATE_PATTERNS.month_full)
  return `${monthName}, ${maxMonthYear.year}`
}

export function groupTimesByDate(appointments: IAppointment[]): DateTimesMap {
  const resultMap: DateTimesMap = {}

  appointments.forEach((_date) => {
    const date = dayjs(_date.AppointmentDateTime)
    const dateTime = date.format(DATE_PATTERNS.iso_8601_date_time_no_seconds)
    const currentDate = new Date(dateTime)
    const dateKey = date.format(DATE_PATTERNS.iso_8601_date)
    const timeValue = date.format(DATE_PATTERNS.time_24)
    const now = new Date()
    const hourDiff = addHours(now, APPOINTMENT_HOUR_DIFF)
    const appointmentLimit = isAfter(currentDate, hourDiff)

    if (resultMap[dateKey] && appointmentLimit) {
      resultMap[dateKey].push(timeValue)
    } else if (appointmentLimit) {
      resultMap[dateKey] = [timeValue]
    }
  })

  return resultMap
}

// custom hook
export const useAppoinments = (initialDate: dayjs.Dayjs) => {
  const now = dayjs()
  const [date, setDate] = useState<dayjs.Dayjs>(initialDate)
  const [appointments, setAppointments] = useState<IAppointment[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>(false)
  const navigate = useNavigate()
  const serviceCenterID = useSelector((s: any) => s.appointmentInfo.ServiceCenterID)
  const language = useSelector((s: any) => s.formInfo.PersonDetails?.Language?.LanguageCode)
  const Guardian1Email = useSelector(
    (s: any) => s.formInfo?.Guardian?.Guardian1Email || s.reschedule.personID,
  )

  // Define the global cutoff date.
  const specialCenters = ['3904795']
  const globalMaxDate = specialCenters.includes(String(serviceCenterID))
    ? dayjs().add(42, 'day')
    : dayjs().add(14, 'day')

  useEffect(() => {
    if (!serviceCenterID) {
      navigate('../', { relative: 'path' })
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(false)
      try {
        const result = await getAvailableAppointmentsSlots(
          date,
          serviceCenterID,
          language,
          Guardian1Email,
        )

        // Filter appointments: they must be in the future and before the global cutoff.
        const filteredAppointments = result?.data.filter(
          (appointment: IAppointment) => {
            const apptTime = dayjs(appointment.AppointmentDateTime)
            return apptTime.isAfter(now) && apptTime.isBefore(globalMaxDate.add(1, 'day'))
          },
        )

        setAppointments(filteredAppointments)
      } catch (err) {
        sendSentryError(`Front: GetAvailableAppointments error ${err}`)
        setError(err)
      }
      setLoading(false)
    }
    fetchData()
  }, [date])

  return {
    date,
    setDate,
    appointments,
    loading,
    error,
  }
}
