import React, { useState, useEffect } from 'react'
import { Box, Link, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import ReactGA from 'react-ga4'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Trans } from 'react-i18next'
import Turnstile from 'react-turnstile'
import {
  PatientInfoForm,
  AddressForm,
  Guardian1Form,
  Guardian2Form,
} from './formDetails'
import { setFormInfo } from '../../../store/formInfo'
import { setZipCode } from '../../../store/zipCode'
import {
  DATE_PATTERNS,
  SUB_COLOR_LIGHT,
  CONTACT_NUMBER,
  DEFAULT_LANGUAGE_VALUE,
  SS_ENDPOINT,
} from '../../../app/constants'
import AppBar from '../../../components/AppBar'
import NextButton from '../../../components/NextButton'
import ViewContainer from '../../../components/ViewContainer'
import BackButton from '../../../components/BackButton'
import { IFormValues } from '../../../app/types'
import sendSentryError from '../../../utils/sentryError'
import { setServiceCenterByState } from '../../../store/serviceCenterByState'
import { setPatientInfo, setPersonID } from '../../../store/reschedule'
import useDialog from '../../../hooks/dialog'
import Dialog from '../../../components/Dialog'
import { setIdentifyPatient } from '../../../store/identifyPatient'
import getAppointmentDuration from '../../../api/getAppointmentDuration'
import { setAppointmentDuration } from '../../../store/appointmentDuration'
import usePathTranslation from '../../../hooks/pathTranslation'

const DescriptionComponent = () => {
  const { t } = usePathTranslation()
  return (
    <NoticeBox>
      <InfoOutlinedIcon
        style={{
          fontSize: '18px',
          marginRight: '1%',
          marginLeft: '5%',
          marginTop: '0.2%',
        }}
      />
      <Box>
        <NoticeText>
          <Trans
            components={[
              <b key="bold" />,
              <Link
                key="link"
                href={`tel:${CONTACT_NUMBER.value}`}
                style={{ whiteSpace: 'nowrap' }}
              />,
            ]}
            i18nKey="self_scheduling.initial_form.description_component"
            t={t}
            values={{ clinicNumber: CONTACT_NUMBER.description }}
          />
        </NoticeText>
      </Box>
    </NoticeBox>
  )
}

const PatientForm = () => {
  const { t } = usePathTranslation()
  const alertHook = useDialog()
  const [isLoading, setLoading] = useState(false)
  const [turnstileVerified, setTurnstileVerified] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Generate a random honeypot field name on mount
  const [honeypotFieldName] = useState(
    () => `hp_${Math.random().toString(36).substring(2, 15)}`,
  )

  const personInfo = useSelector((state: any) => state.formInfo.PersonDetails)
  const guardiansInfo = useSelector((state: any) => state.formInfo.Guardian)
  const {
    Name,
    DOB,
    Gender,
    Language,
    Address,
  } = personInfo || {}
  const {
    Guardian1FirstName,
    Guardian1LastName,
    Guardian1Relation,
    Guardian1Phone,
    Guardian1Email,
    Guardian2FirstName,
    Guardian2LastName,
    Guardian2Relation,
    Guardian2Phone,
    Guardian2Email,
  } = guardiansInfo || {}

  const defaultValues = {
    patientFirstName: Name?.First || '',
    patientLastName: Name?.Last || '',
    dateOfBirth: DOB ? dayjs(DOB).toDate() : null,
    gender: Gender?.Code || null,
    language: Language?.LanguageCode || DEFAULT_LANGUAGE_VALUE,
    firstLine: Address?.Line1 || '',
    secondLine: Address?.Line2 || '',
    country: Address?.Country?.Code || 'US',
    city: Address?.City || '',
    stateCode: Address?.State || '',
    zipCode: Address?.Zip || '',
    guardianFirstName: Guardian1FirstName || '',
    guardianLastName: Guardian1LastName || '',
    guardianRelation: Guardian1Relation?.Code || null,
    guardianPhoneCode: Guardian1Phone?.GuardianCode || '+1',
    guardianPhoneNumber: Guardian1Phone?.GuardianNumber || '',
    guardianEmail: Guardian1Email || '',
    guardian2FirstName: Guardian2FirstName || '',
    guardian2LastName: Guardian2LastName || '',
    guardian2Relation: Guardian2Relation?.Code || null,
    guardian2PhoneCode: Guardian2Phone?.GuardianCode || '+1',
    guardian2PhoneNumber: Guardian2Phone?.GuardianNumber || '',
    guardian2Email: Guardian2Email || '',
    // The honeypot field is intentionally not part of defaultValues
  }

  // Register the form fields including the honeypot; note we use watch to monitor its value
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    clearErrors,
    watch,
  } = useForm<IFormValues>({
    defaultValues,
  })

  // Watch the honeypot field value
  const honeypotValue = watch(honeypotFieldName)
  const isHoneypotFilled = Boolean(honeypotValue && honeypotValue.trim() !== '')

  const handleOnClickBack = (event: any) => {
    event.preventDefault()
    window.location.href = 'https://www.cranialtech.com'
  }

  const checkDuplicatePatient = async (
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    email: string,
  ): Promise<any> => {
    try {
      const path = '/api/check-duplicate-patient'
      const url = new URL(path, SS_ENDPOINT)
      url.searchParams.append('first_name', firstName)
      url.searchParams.append('last_name', lastName)
      url.searchParams.append('date_of_birth', dateOfBirth)
      url.searchParams.append('email', email)
      const response = await axios.get(url.toString())
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getServiceCenterByState = async (): Promise<any> => {
    try {
      const path = '/api/get-service-centers-states'
      const url = new URL(path, SS_ENDPOINT)
      const response = await axios.get(url.toString())
      dispatch(setServiceCenterByState(response.data))
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  useEffect(() => {
    getServiceCenterByState()
  }, [])

  const dispatchFormInfo = (values: IFormValues) => {
    values.dateOfBirth = dayjs(values.dateOfBirth).format(
      DATE_PATTERNS.short_date,
    )
    dispatch(setFormInfo(values))
  }

  const handleOnFinish = () => (values: IFormValues) => {
    setLoading(true)
    const firstName = values.patientFirstName
    const lastName = values.patientLastName
    const dateOfBirth = dayjs(values.dateOfBirth).format(
      DATE_PATTERNS.short_date,
    )
    const email = values.guardianEmail
    checkDuplicatePatient(firstName, lastName, dateOfBirth, email)
      .then((res) => {
        if (!res.is_duplicated) {
          dispatch(
            setPatientInfo({
              personID: 0,
              patientNumber: '',
            }),
          )
          ReactGA.event({
            action: 'Patient information filled',
            category: 'User',
          })
          dispatchFormInfo(values)
          if (values.zipCode) {
            dispatch(setZipCode(values.zipCode))
          }
          if (res.patient_information) {
            dispatch(
              setPatientInfo({
                patientNumber: res.patient_information.patient_number,
                personID: res.patient_information.person_id,
              }),
            )
          }
          submitForm(values)
        } else if (res.appointment_list.is_future) {
          dispatch(
            setIdentifyPatient({
              appointmentList: res.appointment_list.appointments,
              patientFirstName: values.patientFirstName,
              patientLastName: values.patientLastName,
              patientZipCode: res.zipcode,
            }),
          )
          dispatch(setPersonID(res.patient_information.person_id))
          alertHook.setDialog({
            description: t('self_scheduling.initial_form.already_scheduled'),
            title: t('self_scheduling.initial_form.title_alert_duplicate'),
          })
          alertHook.setLinkHook(true)
        } else {
          alertHook.setDialog({
            description: t('self_scheduling.initial_form.already_head_start'),
            title: t('self_scheduling.initial_form.title_alert_duplicate'),
          })
        }
      })
      .catch((err) => {
        dispatchFormInfo(values)
        sendSentryError(`Front: check-duplicate-patient:${err}`)
        const errorMessage = err.response?.data ?? err.message
        navigate('./contact-form', {
          relative: 'path',
          state: { error: `check-duplicate-patient: ${errorMessage}` },
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const navigateToFindClinic = (language: string) => {
    getAppointmentDuration(language).then((res) => {
      dispatch(setAppointmentDuration(parseInt(res, 10)))
      navigate('./find-clinic', { relative: 'path' })
    })
  }

  const submitForm = (values: IFormValues) => {
    values.country = 'US'
    values.language = values.language || DEFAULT_LANGUAGE_VALUE
    const url = new URL('/api/submit-form', SS_ENDPOINT)
    const params = {
      email: values.guardianEmail,
      slug: 'self-scheduling-form-fill',
    }
    url.search = new URLSearchParams(params).toString()

    const formData = new FormData()
    formData.append('input_8', values.language)
    formData.append('input_9', values.firstLine)
    formData.append('input_10', values.secondLine)
    formData.append('input_11', values.country)
    formData.append('input_12', values.city)
    formData.append('input_13', values.stateCode)
    formData.append('input_14', values.zipCode)
    formData.append('input_15', values.guardianFirstName)
    formData.append('input_16', values.guardianLastName)
    formData.append('input_18', values.guardianEmail)
    formData.append(
      'input_19',
      `(${values.guardianPhoneCode})${values.guardianPhoneNumber}`,
    )
    formData.append('input_20', values.guardian2FirstName)
    formData.append('input_21', values.guardian2LastName)
    formData.append('input_23', values.guardian2Email)
    formData.append(
      'input_24',
      `(${values.guardian2PhoneCode})${values.guardian2PhoneNumber}`,
    )
    formData.append('input_25', values.language)
    formData.append('ak_hp_textarea', '')
    formData.append('ak_js', '1689024033724')
    formData.append('gform_field_values', '')
    formData.append('gform_submit', '14')
    formData.append('gform_target_page_number_14', '0')
    formData.append('gform_source_page_number_14', '1')
    formData.append('gform_unique_id', '')
    formData.append('is_submit_14', '1')
    formData.append(
      'state_14',
      'WyJbXSIsIjYzMGRkZDk3OTc5MjJhZWUwNDU3MjRiYjg0NGI4NjljIl0=',
    )
    formData.append('version_hash', 'e044b1e9c6b37eb7d9ad70120b641f2b')
    // Append the honeypot field value (should be empty for human users)
    formData.append(honeypotFieldName, (values as any)[honeypotFieldName] || '')

    axios
      .post(url.toString(), formData)
      .then((response) => {
        if (response.status === 200) {
          navigateToFindClinic(values.language || DEFAULT_LANGUAGE_VALUE)
        } else {
          sendSentryError('Front: submit-form error')
          navigate('./contact-form', {
            relative: 'path',
            state: { error: 'submit-form unexpected error' },
          })
        }
      })
      .catch((err) => {
        sendSentryError(`Front: Submit form error ${err}`)
        navigateToFindClinic(values.language || DEFAULT_LANGUAGE_VALUE)
      })
  }

  return (
    <ViewContainer>
      <AppBar
        description={<DescriptionComponent />}
        maxProgress={4}
        progress={1}
        title={t('self_scheduling.initial_form.title_form')}
      />
      <StyledBox
        component="form"
        onSubmit={handleSubmit(handleOnFinish())}
      >
        {/* Honeypot field for bot prevention placed at the top */}
        <input
          type="text"
          {...register(honeypotFieldName)}
          autoComplete="off"
          name={honeypotFieldName}
          style={{ display: 'none' }}
          tabIndex={-1}
        />
        <PatientInfoForm
          control={control}
          errors={errors}
          required
        />
        <AddressForm
          clearStateErrors={clearErrors}
          control={control}
          errors={errors}
          getAddressValues={getValues}
        />
        <Guardian1Form
          control={control}
          errors={errors}
          required
        />
        <Guardian2Form
          control={control}
          errors={errors}
        />
        {/* Cloudflare Turnstile widget */}
        <div style={{ marginTop: '20px' }}>
          <Turnstile
            onVerify={(token: string) => {
              if (token) {
                setTurnstileVerified(true)
              }
            }}
            sitekey="0x4AAAAAAA9QXUoYnpuUrvjp"
            theme="light"
          />
        </div>
        {/* Next button enabled only if Turnstile is verified and honeypot is not filled */}
        <NextButton
          isDisabled={!turnstileVerified || isHoneypotFilled}
          isLoading={isLoading}
          secondary
          text={t('self_scheduling.initial_form.next')}
        />
        <BackButton onClick={handleOnClickBack} />
      </StyledBox>
      <StyledMessage>{t('self_scheduling.initial_form.footer_form')}</StyledMessage>
      <Dialog dialogHook={alertHook} />
    </ViewContainer>
  )
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`

const NoticeBox = styled(Box)`
  background-color: #d9d9d94d;
  display: flex;
  flex-direction: row;
  padding-bottom: 2%;
  padding-top: 2%;
  text-align: left;
`

const NoticeText = styled(Typography)`
  margin-right: 3%;
  white-space: initial;
`

const StyledMessage = styled(Typography)`
  color: ${SUB_COLOR_LIGHT};
  font-size: 14px;
  margin-top: 15px;
`

export default PatientForm
